var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.userData === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Error fetching user data ")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" No user found with this user id. Check "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-users-list'
      }
    }
  }, [_vm._v(" User List ")]), _vm._v(" for other users. ")], 1)]), _vm.userData ? [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "lg": "8",
      "md": "7"
    }
  }, [_c('user-view-user-info-card', {
    attrs: {
      "user-data": _vm.userData
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "xl": "3",
      "lg": "4"
    }
  }, [_c('user-view-user-plan-card')], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('user-view-user-timeline-card')], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('user-view-user-permissions-card')], 1)], 1), _c('invoice-list')] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }