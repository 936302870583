var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-between flex-column",
    attrs: {
      "cols": "21",
      "xl": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start"
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.userData.avatar,
      "text": _vm.avatarText(_vm.userData.fullName),
      "variant": "light-".concat(_vm.resolveUserRoleVariant(_vm.userData.role)),
      "size": "104px",
      "rounded": ""
    }
  }), _c('div', {
    staticClass: "d-flex flex-column ml-1"
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.userData.fullName) + " ")]), _c('span', {
    staticClass: "card-text"
  }, [_vm._v(_vm._s(_vm.userData.email))])]), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('b-button', {
    attrs: {
      "to": {
        name: 'apps-users-edit',
        params: {
          id: _vm.userData.id
        }
      },
      "variant": "primary"
    }
  }, [_vm._v(" Edit ")]), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "outline-danger"
    }
  }, [_vm._v(" Delete ")])], 1)])], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-primary",
      "rounded": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon",
      "size": "18"
    }
  })], 1), _c('div', {
    staticClass: "ml-1"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" 23.3k ")]), _c('small', [_vm._v("Monthly Sales")])])], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-success",
      "rounded": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TrendingUpIcon",
      "size": "18"
    }
  })], 1), _c('div', {
    staticClass: "ml-1"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" $99.87k ")]), _c('small', [_vm._v("Annual Profit")])])], 1)])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UserIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Username")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.userData.username) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CheckIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Status")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.userData.status) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "StarIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Role")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.userData.role) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "FlagIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Country")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.userData.country) + " ")])]), _c('tr', [_c('th', [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "PhoneIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Contact")])], 1), _c('td', [_vm._v(" " + _vm._s(_vm.userData.contact) + " ")])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }