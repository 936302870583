var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "User Timeline"
    }
  }, [_c('app-timeline', [_c('app-timeline-item', [_c('div', {
    staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
  }, [_c('h6', [_vm._v("12 Invoices have been paid")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("12 min ago")])]), _c('p', [_vm._v("Invoices have been paid to the company.")]), _c('p', {
    staticClass: "mb-0"
  }, [_c('b-img', {
    staticClass: "mr-1",
    attrs: {
      "src": require('@/assets/images/icons/pdf.png'),
      "height": "auto",
      "width": "20"
    }
  }), _c('span', {
    staticClass: "align-bottom"
  }, [_vm._v("invoice.pdf")])], 1)]), _c('app-timeline-item', {
    attrs: {
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
  }, [_c('h6', [_vm._v("Client Meeting")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("45 min ago")])]), _c('p', [_vm._v("Project meeting with john @10:15am.")]), _c('b-media', {
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-avatar', {
          attrs: {
            "src": require('@/assets/images/avatars/8-small.png')
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h6', [_vm._v("John Doe (Client)")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" CEO of Infibeam ")])])], 1), _c('app-timeline-item', {
    attrs: {
      "variant": "info",
      "title": "Create a new project for client",
      "subtitle": "Add files to new design folder",
      "time": "2 days ago"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }