var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "border-primary",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "d-flex justify-content-between align-items-center pt-75 pb-25"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Current Plan ")]), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Basic ")]), _c('small', {
    staticClass: "text-muted w-100"
  }, [_vm._v("July 22, 2021")])], 1), _c('b-card-body', [_c('ul', {
    staticClass: "list-unstyled my-1"
  }, [_c('li', [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v("5 Users")])]), _c('li', {
    staticClass: "my-25"
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v("10 GB storage")])]), _c('li', [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Basic Support")])])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v(" Upgrade Plan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }