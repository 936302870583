var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_vm._v("Permissions")]), _c('b-card-sub-title', [_vm._v("Permission according to roles")])], 1), _c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "striped": "",
      "responsive": "",
      "items": _vm.permissionsData
    },
    scopedSlots: _vm._u([{
      key: "cell(module)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value) + " ")];
      }
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "disabled": "",
            "checked": data.value
          }
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }